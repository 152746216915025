import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'
import 'prismjs/themes/prism-tomorrow.css'
import { GlobalStyle } from './Commons'
import { media } from '../tokens'
import CookieConsent from 'react-cookie-consent';

const SiteContent = styled.div`
  margin: 0 0;

  @media ${media.medium} {
    margin: 60px 0;
  }
`

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Lato:400,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <GlobalStyle />
        <Header />
        <SiteContent>{children}</SiteContent>
        <Footer />
        <CookieConsent
          buttonText="Accept"
          enableDeclineButton
          location="bottom"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
          We use cookies and other tracking technologies to improve your browsing experience on our website, to analyze our website traffic, and to understand where our visitors are coming from.
          By browsing our website, you consent to our use of cookies and other tracking technologies.
        </CookieConsent>
      </>
    )
  }
}

export default Template
